<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { boxed } from '@syncedstore/core';
import { DoubleMaterialityDownloadDocumentEnum } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtBadge, { BadgeType } from '@/components/atoms/AtBadge.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlCommentBox from '@/components/molecules/MlCommentBox.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { IroImpact, IroRiskAndOpportunity, Topic } from '../../types';
import { useStoreMethods } from '../../store';
import { useDocumentDownload } from '../../composables/useDocumentDownload';
import MlInfo from '../../MlInfo.vue';
import OgDocument, { type UploadedDocument } from '../../OgDocument.vue';
import OgTable from '../../OgTable.vue';

export type RowItem = {
  _id: string;
  impact: IroImpact | null;
  riskAndOpportunity: IroRiskAndOpportunity | null;
  topic: Topic;
  materiality: boolean | null;
};

defineProps<{
  title: string;
  infoType: 'warning' | 'error' | 'success';
  headers: { text: string, value: string, columnClasses?: string }[];
  items: RowItem[];
  goBackRoute: string;
  nextRoute: string;
}>();

const { t } = useI18n();
const router = useRouter();
const guidanceDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.IMPACTS_SCORING);
const { calculateRecommendedESRS } = useStoreMethods();

const { currentUser } = useCurrentUser();

const options = computed(() => ({
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
}));

function handleSelectChange(
  row: RowItem,
  field: 'scale' | 'scope' | 'irremediability' | 'likelihood' | 'potentialMagnitude',
  event: string | string[],
) {
  if (row.impact) {
    switch (field) {
      case 'scale':
      case 'scope':
      case 'irremediability':
      case 'likelihood':
        row.impact[field] = Number(event);

        calculateRecommendedESRS();
        break;
      default:
        throw new Error('Wrong field');
    }
  } else if (row.riskAndOpportunity) {
    switch (field) {
      case 'likelihood':
      case 'potentialMagnitude':
        row.riskAndOpportunity[field] = Number(event);

        calculateRecommendedESRS();
        break;
      default:
        throw new Error('Wrong field');
    }
  }
}
</script>

<template>
  <MlInfo :type="infoType" class="mb-10">
    <slot />
  </MlInfo>
  <h3 class="text-lg font-medium mb-7">
    {{ title }}
  </h3>
  <OgTable
    :headers="headers"
    :items="items"
    :isRowRemovable="false"
  >
    <template #item-impact="row: RowItem">
      <VTooltip v-if="row.impact" autoHide>
        <p>
          {{ row.impact.impact }}
        </p>
        <template #popper>
          {{ row.topic.esrs }} {{ row.topic.topic }} / {{ row.topic.subTopic }} / {{ row.topic.subSubTopic }}
        </template>
      </VTooltip>
    </template>
    <template #item-riskAndOpportunity="row: RowItem">
      <VTooltip v-if="row.riskAndOpportunity" autoHide>
        <p>
          {{ row.riskAndOpportunity.riskAndOpportunity }}
        </p>
        <template #popper>
          {{ row.topic.esrs }} {{ row.topic.topic }} / {{ row.topic.subTopic }} / {{ row.topic.subSubTopic }}
        </template>
      </VTooltip>
    </template>
    <template #item-scale="row: RowItem">
      <MlSelect
        v-if="row.impact"
        :modelValue="row.impact.scale?.toString() ?? undefined"
        :options="options"
        @update:modelValue="handleSelectChange(row, 'scale', $event)"
      />
    </template>
    <template #item-scope="row: RowItem">
      <MlSelect
        v-if="row.impact"
        :modelValue="row.impact.scope?.toString() ?? undefined"
        :options="options"
        @update:modelValue="handleSelectChange(row, 'scope', $event)"
      />
    </template>
    <template #item-irremediability="row: RowItem">
      <MlSelect
        v-if="row.impact"
        :modelValue="row.impact.irremediability?.toString() ?? undefined"
        :options="options"
        @update:modelValue="handleSelectChange(row, 'irremediability', $event)"
      />
    </template>
    <template #item-likelihood="row: RowItem">
      <MlSelect
        v-if="row.impact"
        :modelValue="row.impact.likelihood?.toString() ?? undefined"
        :options="options"
        @update:modelValue="handleSelectChange(row, 'likelihood', $event)"
      />
      <MlSelect
        v-else-if="row.riskAndOpportunity"
        :modelValue="row.riskAndOpportunity.likelihood?.toString() ?? undefined"
        :options="options"
        @update:modelValue="handleSelectChange(row, 'likelihood', $event)"
      />
    </template>
    <template #item-potentialMagnitude="row: RowItem">
      <MlSelect
        v-if="row.riskAndOpportunity"
        :modelValue="row.riskAndOpportunity.potentialMagnitude?.toString() ?? undefined"
        :options="options"
        @update:modelValue="handleSelectChange(row, 'potentialMagnitude', $event)"
      />
    </template>
    <template #item-materiality="row: RowItem">
      <AtBadge :type="row.materiality === true ? BadgeType.SUCCESS : (row.materiality === false ? BadgeType.WARNING : BadgeType.DEFAULT)">
        {{ row.materiality === true ? t('Material') : (row.materiality === false ? t('Not material') : t('Start')) }}
      </AtBadge>
    </template>
    <template #expand="row: RowItem">
      <div class="mb-4 flex justify-between items-center">
        <div class="flex space-x-2.5">
          <OgDocument
            v-if="row.impact"
            :userId="currentUser?._id!"
            :file="(row.impact.scoreDocument ?? null) as unknown as UploadedDocument"
            @update:file="row.impact.scoreDocument = boxed($event)"
          />
          <OgDocument
            v-else-if="row.riskAndOpportunity"
            :userId="currentUser?._id!"
            :file="(row.riskAndOpportunity.scoreDocument ?? null) as unknown as UploadedDocument"
            @update:file="row.riskAndOpportunity.scoreDocument = boxed($event)"
          />
          <MlCommentBox
            v-if="row.impact"
            v-model="row.impact.scoreComment"
            iconSize="sm"
            class="text-xs text-gray-400"
          />
          <MlCommentBox
            v-else-if="row.riskAndOpportunity"
            v-model="row.riskAndOpportunity.scoreComment"
            iconSize="sm"
            class="text-xs text-gray-400"
          />
        </div>
      </div>
    </template>
  </OgTable>

  <div class="mt-auto flex items-center justify-between">
    <div class="flex space-x-3">
      <AtButton
        type="button"
        variant="outline"
        @click="router.push({ name: goBackRoute })"
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        variant="outline"
        :loading="guidanceDownload.loading.value"
        @click="guidanceDownload.exportFile"
      >
        {{ t('Download guidance') }}
      </AtButton>
    </div>
    <AtButton
      type="button"
      @click="router.push({ name: nextRoute })"
    >
      {{ t('Next') }}
    </AtButton>
  </div>
</template>
